// Load Styles
import '../scss/main.scss';

import $ from 'jquery';

// Load Bootstrap init
import "./bootstrap.js";

import 'select2';
// import 'jquery-match-height';
import 'slick-carousel';

//## Your app code
// console.log(`Hello ${process.env.HELLO}`);

$(function () {
  //## jQuery
  // const winH = $(window).height();
  // console.log(`Window height: ${winH}`);
  const winW = $(window).width();
  // console.log(`Window width: ${winW}`);

  if (winW <= 768) {
    $("#navbars .nav-link").on("click", function (e) {
      $(".navbar-toggler").trigger("click");
    });
  }

  //## Slick
  if ($.fn.slick) {
    $("#brand-slider").slick({
      // dots: false,
      // arrows: true,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 1000,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      variableWidth: true,
      // fade: true,
      // cssEase: 'linear',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 4
          },
        },
        // {
        //   breakpoint: 480,
        //   settings: {
        //     dots: true,
        //     arrows: false,
        //     slidesToShow: 3
        //   },
        // },
      ],
    });

    // custom slick when hovering over
    let slider;
    const sliderClicked = function (el) {
      slider = setInterval(function () {
        el.trigger("click");
      }, 1000);
    };

    $("#brand-slider .slick-arrow")
      .on('mouseenter', function () {
        $(this).trigger("click");

        sliderClicked($(this));
      })
      .on('mouseleave', function () {
        clearInterval(slider);
      })
      ;
  }

  //## select2
  var select2Init = function () {
    if ($.fn.select2) {
      // $.fn.select2.defaults.set('theme', 'bootstrap');
      // $('.select2').select2();

      $('.select2').select2({
        theme: "bootstrap-5",
      });
    }
  };

  select2Init();

});
